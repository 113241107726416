const Login = () => import('@pages/Login.vue')
const Register = () => import('@pages/Register.vue')
const ForgotPassword = () => import('@pages/ForgotPassword.vue')
const RecoverPassword = () => import('@pages/RecoverPassword.vue')
const Main = () => import('@pages/Main.vue')
const Navbar = () => import('@components/Navbar.vue')
const Sidebar = () => import('@components/Sidebar.vue')
const Header = () => import('@components/Header.vue')
const Footer = () => import('@components/Footer.vue')
const AssignVending = () => import('@pages/AssignVending.vue')
const AssignVendingDinamis = () => import('@pages/AssignVendingDinamis.vue')

const mainPages = require('./main-pages')

export default {
    routes: [
        {
            path: '',
            component: {
                template: '<router-view></router-view>'
            },
            children: [
                {
                    path: '',
                    component: Main,
                    children: [
                        {
                            path: '',
                            components: {
                                default: {
                                    template: '<router-view></router-view>'
                                },
                                sidebar: Sidebar,
                                navbar: Navbar,
                                header: Header,
                                // footer: Footer
                            },
                            children: mainPages
                        }
                    ]
                }, {
                    path: '/login',
                    name: 'login',
                    component: Login
                }, {
                    path: '/register',
                    name: 'register',
                    component: Register
                }, {
                    path: '/forgot-password',
                    name: 'forgot_password',
                    component: ForgotPassword
                }, {
                    path: '/recover-password',
                    name: 'recover_password',
                    component: RecoverPassword
                },
                {
                    path: '/AssignVending',
                    name: 'AssignVending',
                    component: AssignVending
                },
                {
                    path: '/AssignVendingDinamis',
                    name: 'AssignVendingDinamis',
                    component: AssignVendingDinamis
                }
            ]
        }
    ]
}
