const Dashboard = () => import('@pages/Dashboard.vue')
const Error404 = () => import('@pages/Error404.vue')
const Error500 = () => import('@pages/Error500.vue')
const Profile = () => import('@pages/Profile.vue')
const Merchant = () => import('@pages/Merchant.vue')
const MerchantDetail = () => import('@pages/MerchantDetail.vue')
const RefundRequest = () => import('@pages/RefundRequest.vue')
const Product = () => import('@pages/Product.vue')
const Sensor = () => import('@pages/Sensor.vue')
const Vending = () => import('@pages/Vending.vue')
const RiwayatSaldo = () => import('@pages/LogSaldo.vue')
const Games = () => import('@pages/Games.vue')
const VendingType = () => import('@pages/VendingType.vue')
const ReportSale =() => import('@pages/ReportSale.vue')
const ReportSaleOfGoods = () => import('@pages/ReportSaleOfGoods.vue')
const PaymentGateway = () => import('@pages/PaymentGateway')


module.exports = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
    }, {
        path: '/error404',
        name: 'error404',
        component: Error404
    }, {
        path: '/error500',
        name: 'error500',
        component: Error500
    },{
        path: '/profile',
        name: 'profile',
        component: Profile
    },
    {
        path: '/Merchant',
        name: 'Merchant',
        component: Merchant,
    },
    {
        path: '/MerchantDetail',
        name: 'MerchantDetail',
        component: MerchantDetail,
    },
    {
        path: '/RefundRequest',
        name: 'RefundRequest',
        component : RefundRequest,
    },
    {
        path: 'Product',
        name: 'Product',
        component: Product,
    },
    {
        path: 'Vending',
        name: 'Vending',
        component: Vending,
    },
    {
        path: 'Sensor',
        name: 'Sensor',
        component: Sensor,
    },
    {
        path: 'RiwayatSaldo',
        name: 'RiwayatSaldo',
        component : RiwayatSaldo,
    },
    {
        path: 'Games',
        name: 'Games',
        component : Games,
    },
    {
        path: 'VendingType',
        name: 'VendingType',
        component: VendingType
    },
    {
        path: 'Sale',
        name :'ReportSale',
        component: ReportSale,
    },
    {
        path: 'SaleOfGoods',
        name :'ReportSaleOfGoods',
        component: ReportSaleOfGoods,
    },
    {
        path: 'PaymentGateway',
        name :'PaymentGateway',
        component: PaymentGateway,
    }

    
]
